@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom App */

/* .no-scroll ::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
} */

/* Optional: show position indicator in red */
/* .no-scroll ::-webkit-scrollbar-thumb {
    background: #FF0000;
} */

html {
    scroll-behavior: smooth;
}

details {
    user-select: none;
}

details summary svg {
    transform: rotate(90deg);
}

details[open] summary svg {
    transform: rotate(-90deg);
}

details[open] summary~* {
    animation: ease-opacity-t-b 0.5s ease;
}

summary {
    cursor: pointer;
}

svg {
    transition: all 0.3s;
}

/* TO JE TO - TO JE TAJ */
summary::marker {
    display: none;
}

[x-cloak] {
    display: none;
}

@keyframes loader-rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader {
    border-right-color: transparent;
    animation: loader-rotate 1s linear infinite;
}

.filepond--credits {
    display: none !important;
}

.filepond--drip {
    @apply bg-gray-100;
    @apply opacity-100;
}

.filter-blur {
    backdrop-filter: saturate(150%) blur(15px);
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}

.cms-content a {
    @apply text-blue-600 underline hover:bg-purple-600 hover:text-white;
}